// @flow
import React, { useEffect, useRef, useState } from 'react';
// Components
import PanelCard from '../PanelCard';
import CreditCardEditor from './CreditCardEditor';
import CreditCardList from '../../containers/CreditCardList';
import CheckoutErrors from '../CheckoutErrors';
import { Button, RadioButton, validationHelpers } from '../../../common';
import Select from 'react-select';
// HOCs
import {
  userAllowedPaymentMethodsConnector,
  userCreditCardsConnector,
} from '../../../user';
// Lodash
import _ from 'lodash';
// Analytics
import * as Analytics from '../../../common/analytics';
//Style
import './PaymentPane.css';
// Types
import type {
  PaymentSource,
  PaymentMethod,
  PaymentMethodType,
  CreditCard,
  PaymentSourceCreditCard,
} from '../../types';
// Logger
import { logMessage } from '../../../logHelper';
// Other
import { phone } from '../../../common';
import PaymentOptionBox from '../PaymentOptionBox';
import CardBrandOptionBox from '../CardBrandOptionBox';

const acceptanceTypes = {
  debit: 'Débito',
  credit: 'Crédito',
  food_card: 'Alimentação',
  restaurant_card: 'Refeição',
};

type Props = {
  orderNumber: string,
  errors: string[],
  promotionErrors: string[],
  selected: PaymentMethod,
  active: boolean,
  paymentMethods: PaymentMethod[],
  creditCards: CreditCard[],
  promotions: any,
  promotionsLoading: boolean,
  loading: boolean,
  bigClubCreditAmount: number,
  submitPayment: (
    method: PaymentMethod,
    source: CreditCard | PaymentSource,
    existing?: boolean,
    bigClubCreditAmount: number,
  ) => void,
  useBigClubCredit: boolean,
  submitPromotion: (couponCode: string) => void,
  adjustmentTotal: string,
  dismissErrors: () => void,
  // From connector
  allowedPaymentMethods: PaymentMethod[],
  creditCards: CreditCard[],
  totalPrice: string | number,
  cashbackMinOrderPriceToEarn: string | number,
  transitionToPayment: () => void,
  isCompleted: boolean,
};

type PaymentState = {
  selected: ?PaymentMethod,
  existingCard: boolean,
  paymentSource: null | CreditCard | PaymentSource,
  editing: boolean,
  newCardValidationErrors: {
    name: string,
    number: string,
    verificationValue: string,
    year: string,
    month: string,
  },
  newCard: boolean,
  showOnlinePaymentMethodsOnly: boolean,
};

const createInitialPaymentState = () => ({
  selected: null,
  paymentSource: null,
  editing: false,
  existingCard: false,
  newCardValidationErrors: {
    name: '',
    number: '',
    verificationValue: '',
    year: '',
    month: '',
  },
  newCard: false,
  selectedPromotions: [],
  showOnlinePaymentMethodsOnly: true,
});

const PaymentPane = (props: Props) => {
  const paymentMethodsLengthRef = useRef(null);
  const creditCardsLengthRef = useRef(null);
  const isActiveRef = useRef(null);

  const [paymentState, setPaymentState] = useState<PaymentState>(
    createInitialPaymentState(props),
  );

  useEffect(() => {
    creditCardsLengthRef.current = props.creditCards.length;
    paymentMethodsLengthRef.current = props.paymentMethods.length;
    isActiveRef.current = props.active;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      props.creditCards.length === 0 &&
      paymentMethodsLengthRef.current === 0 &&
      props.paymentMethods.length !== 0
    ) {
      paymentMethodsLengthRef.current = props.paymentMethods.length;
      openCardEditor();
    }
    if (creditCardsLengthRef.current === 0 && props.creditCards.length !== 0) {
      creditCardsLengthRef.current = props.creditCards.length;
      closeCardEditor();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isActiveRef.current !== props.active) {
      isActiveRef.current = props.active;
      setPaymentState(createInitialPaymentState());
    }
  }, [props.active]);

  const getOnlineGateway = (paymentMethods: PaymentMethod[]) => {
    const filteredMethods = paymentMethods.filter(
      method => method.methodType === 'gateway',
    );
    return filteredMethods.length > 0 ? filteredMethods[0] : null;
  };

  const selectExistingCard = (card: CreditCard) => {
    const gatewayMethod = getOnlineGateway(props.paymentMethods);
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      selected: gatewayMethod,
      paymentSource: card,
      existingCard: true,
      editing: false,
      newCard: false,
    }));
  };

  const setShowOnlinePaymentMethodsOnly = (
    showOnlinePaymentMethodsOnly: boolean,
  ) => {
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      showOnlinePaymentMethodsOnly,
    }));
  };

  const openCardEditor = (existingCard: boolean = false) => {
    const gatewayMethod = getOnlineGateway(props.paymentMethods);
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      selected: gatewayMethod,
      paymentSource: {
        name: '',
        number: '',
        month: '',
        year: '',
        verificationValue: '',
      },
      editing: true,
      existingCard,
      newCard: true,
    }));
  };

  const closeCardEditor = () => {
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      editing: false,
    }));
  };

  const addNewCard = (card: PaymentSourceCreditCard) => {
    const gatewayMethod = getOnlineGateway(props.paymentMethods);
    Analytics.logAddNewCard(card);
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      selected: gatewayMethod,
      paymentSource: card,
      existingCard: false,
      newCard: true,
    }));
  };

  const clearSelectedPaymentMethod = () => {
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      selected: null,
    }));
  };

  const selectOfflinePaymentMethod = (method: PaymentMethod) => {
    setPaymentState((paymentState: paymentState): paymentState => ({
      ...paymentState,
      selected: method,
      editing: false,
      existingCard: false,
      paymentSource: { ...method.methodType, cardBrandId: undefined },
      newCard: false,
    }));
  };

  const selectCardBrand = (cardBrandId: number) => {
    setPaymentState({
      ...paymentState,
      paymentSource: {
        ...paymentState.paymentSource,
        cardBrandId,
      },
    });
  };

  const handleLogBigClubAnalytics = () => {
    if (props.useBigClubCredit) {
      Analytics.logUserAppliedCashback();
    }
    if (Number(props.totalPrice) >= Number(props.cashbackMinOrderPriceToEarn)) {
      Analytics.logUserReachedMinPriceToEarnCashback();
    }
  };

  const submitPayment = (e: Event | undefined) => {
    e && e.preventDefault();
    handleLogBigClubAnalytics();
    const { selected, paymentSource, existingCard, newCard } = paymentState;
    // If the payment source is a new credit card than we should validate the input
    // TODO: What happens if the paymentSource is cash?
    let noValidationErrors: boolean = true;

    let name = '';
    let number = '';
    let verificationValue = '';
    let year = '';
    let month = '';

    if (paymentSource && newCard) {
      name = validationHelpers.creditCard.name(paymentSource.name);
      if (name) {
        noValidationErrors = false;
      }

      number = validationHelpers.creditCard.number(paymentSource.number);
      if (number) {
        noValidationErrors = false;
      }

      verificationValue = validationHelpers.creditCard.cvv(
        paymentSource.verificationValue,
      );
      if (verificationValue) {
        noValidationErrors = false;
      }

      year = validationHelpers.creditCard.year(paymentSource.year);
      if (year) {
        noValidationErrors = false;
      }

      month = validationHelpers.creditCard.month(paymentSource.month);
      if (month) {
        noValidationErrors = false;
      }
    }

    const newCardValidationErrors = {
      name,
      number,
      verificationValue,
      year,
      month,
    };

    setPaymentState(prevPaymentState => ({
      ...prevPaymentState,
      newCardValidationErrors,
    }));

    if (selected != null && noValidationErrors) {
      props.submitPayment(
        selected,
        paymentSource,
        existingCard,
        props.useBigClubCredit ? props.bigClubCreditAmount : 0,
      );
      Analytics.logCompletedOrder();
    } else {
      console.error('Validation error on payment form.');
      logMessage('Validation error on payment form.', null, 'error');
    }
  };

  const getPaymentMethods = (gatewayMethod: PaymentMethodType) => {
    // Methods allowed for the specific user
    const userAllowedPaymentMethods = _.intersectionBy(
      props.paymentMethods,
      props.allowedPaymentMethods,
      'name',
    );
    // Methods that have visibility controlled globally
    const globallyAllowedPaymentMethods = props.paymentMethods.filter(
      paymentMethod =>
        paymentMethod && paymentMethod.displayToAllUsersRegardlessOfPermission,
    );
    // Put them all together but remove any nulls or duplicates
    return _.uniqBy(
      [
        gatewayMethod,
        ...userAllowedPaymentMethods,
        ...globallyAllowedPaymentMethods,
      ].filter(paymentMethod => paymentMethod !== null),
      'id',
    );
  };

  const gatewayMethod = getOnlineGateway(props.paymentMethods);
  const paymentMethods = getPaymentMethods(gatewayMethod);
  const availablePromotions = props.promotions.map(promotion => {
    return { value: promotion.code, label: promotion.name };
  });
  const visiblePaymentMethods = paymentMethods.filter(
    (paymentMethod, index) =>
      paymentMethod.isOnlinePayment ===
      paymentState.showOnlinePaymentMethodsOnly,
  );
  const userCanPayOnDelivery =
    paymentMethods.findIndex(
      paymentMethod => !paymentMethod.isOnlinePayment,
    ) !== -1;
  const userHasAtLeastOneSavedCreditCard = props.creditCards.length > 0;
  const handleSubmitPromotion = promotion => {
    setPaymentState({ selectedPromotions: promotion });
    props.submitPromotion(promotion.value);
  };
  const shouldShowAcceptedCardBrands =
    paymentState.selected &&
    !paymentState.existingCard &&
    paymentState.selected.acceptedCardBrands &&
    paymentState.selected.acceptedCardBrands.length > 0;
  const acceptedCardBrandSelectedIfRequired = !(
    shouldShowAcceptedCardBrands && !paymentState.paymentSource.cardBrandId
  );

  return (
    <PanelCard
      isOpen={props.active}
      number={3}
      title="Escolha como pagar"
      handleClick={() => props.transitionToPayment()}
      isCompleted={props.isCompleted}
      isLoading={props.loading}
    >
      {props.active && (
        <div className="payment-pane-content">
          <h3 className="payment-pane-payment-information-title">
            Selecione seu método de pagamento
          </h3>
          <p style={{ color: '#ffb533' }}>
            Pagamento por pix temporariamente indisponível
          </p>
          {userCanPayOnDelivery && (
            <>
              <RadioButton
                isSelected={paymentState.showOnlinePaymentMethodsOnly}
                text="Pagamento Online"
                onSelect={() => {
                  setShowOnlinePaymentMethodsOnly(true);
                  clearSelectedPaymentMethod();
                }}
              />
              <RadioButton
                isSelected={!paymentState.showOnlinePaymentMethodsOnly}
                text="Pagamento na Entrega"
                onSelect={() => {
                  setShowOnlinePaymentMethodsOnly(false);
                  clearSelectedPaymentMethod();
                }}
              />
            </>
          )}

          <div className="payment-pane-method-content">
            <div className="payment-options-content">
              {visiblePaymentMethods &&
                visiblePaymentMethods.length > 0 &&
                visiblePaymentMethods.map((paymentMethod, index) => (
                  <PaymentOptionBox
                    iconSize="small"
                    iconUrl={paymentMethod.iconUrl}
                    key={index}
                    isSelected={
                      paymentState.selected &&
                      paymentState.selected.id === paymentMethod.id
                    }
                    text={paymentMethod.name}
                    onSelect={() => selectOfflinePaymentMethod(paymentMethod)}
                  />
                ))}
            </div>
          </div>
          {shouldShowAcceptedCardBrands && (
            <div className="payment-pane-card-options-main-div">
              <h3 className="payment-pane-payment-information-title">
                Selecione a bandeira e o tipo do seu cartão.
              </h3>
              <div className="payment-pane-container">
                {_.sortBy(
                  paymentState.selected.acceptedCardBrands,
                  acceptedCardBrand => [
                    acceptedCardBrand.acceptanceType === 'food_card' ? 1 : 0,
                    acceptedCardBrand.name,
                  ],
                ).map((acceptedCardBrand, index) => (
                  <CardBrandOptionBox
                    iconUrl={acceptedCardBrand.iconUrl}
                    key={index}
                    isSelected={
                      paymentState.paymentSource &&
                      paymentState.paymentSource.cardBrandId ===
                        acceptedCardBrand.id
                    }
                    text={`${acceptedCardBrand.name} - ${
                      acceptanceTypes[acceptedCardBrand.acceptanceType]
                    }`}
                    onSelect={() => selectCardBrand(acceptedCardBrand.id)}
                  />
                ))}
              </div>
            </div>
          )}
          {!!props.promotions && props.promotions.length > 0 && (
            <div className="payment-pane-select-coupon">
              <CheckoutErrors
                errors={props.promotionErrors}
                dismiss={props.dismissErrors}
                showErrorFields={false}
              />
              <h3 className="payment-pane-payment-information-title">
                Cupom de Desconto
              </h3>
              {(!Number(props.adjustmentTotal) && (
                <p className="payment-pane-discount-coupon-information">
                  Possui cupons de desconto? É possível selecionar estes nesse
                  momento.
                </p>
              )) || (
                <p className="payment-pane-discount-coupon-apply-information">
                  {`Foi aplicado um desconto de ${Math.abs(
                    parseFloat(props.adjustmentTotal),
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}`}
                </p>
              )}
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: '#c0e8b7',
                  },
                })}
                placeholder={'Pesquise por seu Cupom'}
                loading={props.promotionsLoading || props.loading}
                onChange={value => handleSubmitPromotion(value)}
                options={availablePromotions}
              />
            </div>
          )}
          <div className="payment-options-credit-card">
            <CheckoutErrors
              errors={props.errors}
              dismiss={props.dismissErrors}
            />
            {gatewayMethod && (
              <div>
                {paymentState.selected === gatewayMethod ? (
                  paymentState.editing || !userHasAtLeastOneSavedCreditCard ? (
                    <>
                      {userHasAtLeastOneSavedCreditCard && (
                        <div className="payment-button-container">
                          <Button
                            buttonType="button-secondary"
                            onClick={closeCardEditor}
                            text="Mostrar cartões salvos"
                          />
                        </div>
                      )}
                      <CreditCardEditor
                        card={paymentState.paymentSource}
                        validationErrors={paymentState.newCardValidationErrors}
                        onUpdate={addNewCard}
                      />
                    </>
                  ) : (
                    <div className="payment-pane-card-options">
                      <div className="payment-button-container">
                        <Button
                          buttonType="button-secondary"
                          text="Adicionar novo cartão"
                          onClick={() => openCardEditor()}
                        />
                      </div>
                      <CreditCardList
                        onClick={selectExistingCard}
                        selectedCardId={
                          paymentState.paymentSource &&
                          paymentState.paymentSource.id
                            ? paymentState.paymentSource.id
                            : null
                        }
                      />
                    </div>
                  )
                ) : null}
              </div>
            )}

            <div className="payment-pane-footer">
              <div className="payment-pane-methods-message">
                * Para outros métodos de pagamento entre em contato conosco pelo
                whatsapp {phone.asStringFull}. Seu código de pedido é{' '}
                {props.orderNumber}.
              </div>
            </div>
            <Button
              onClick={submitPayment}
              disabled={
                !paymentState.selected || !acceptedCardBrandSelectedIfRequired
              }
              buttonType="button-primary"
              text="Confirmar Pagamento"
              loading={props.loading}
            />
          </div>
        </div>
      )}
    </PanelCard>
  );
};

export default userCreditCardsConnector(
  userAllowedPaymentMethodsConnector(PaymentPane),
);
